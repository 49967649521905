import Carousel from 'react-multi-carousel';
import { useMediaQuery } from 'react-responsive';
import dayjs from 'dayjs';
import 'dayjs/locale/th';
import Image from 'src/components/image';

import buddhistEra from 'dayjs/plugin/buddhistEra';
import { sendDataLayerGlobal } from 'src/utils/datalayer/send-datalayer';
import _ from 'lodash';
import { useState } from 'react';
dayjs.extend(buddhistEra);
const reOrderingData = (i) => {
  const aa = [1, 2, 3, 4, 5];
  if (i > 3) {
    for (let index = 0; index < (i - 3); index++) {
      aa.push(aa.shift());
    }
  } else if (i < 3) {
    for (let index = 0; index < (3 - i); index++) {
      aa.unshift(aa.pop());
    }
  } else {
    return aa;
  }
  return aa;
};
const CarouselFull = ({ data, eventDataLayer }) => {
  if (data?.lenght < 1) return new Array(5).fill(null);
  const isSmallScreen = useMediaQuery({
    query: '(max-device-width: 640px)'
  });
  const [actived, setActived] = useState(0);
  const CustomDot = ({ onClick, ...rest }) => {
    const {
      index,
      active
      // carouselState: { currentSlide }
    } = rest;
    if (active) {
      setActived(index + 1);
    }
    // console.log('asaas', actived, index + 1, reOrderingData(actived), reOrderingData(actived).indexOf(index + 1));
    const carouselItems = [...data].filter((item, i) => i === index);
    return (
      <a
        //! DATALAYER
        href={carouselItems[0].link}
        onClick={() =>
          sendDataLayerGlobal({
            ...eventDataLayer,
            data: {
              ...eventDataLayer.data,
              title: carouselItems[0]?.title,
              carousel: index + 1
            }
          })
        }
        className={active ? `active active-order-${reOrderingData(actived).indexOf(index + 1)}` : `inactive active-order-${reOrderingData(actived).indexOf(index + 1)}`}
      >
          {isSmallScreen ? (
            <p className='text-excerpt-3'>
              <span>{dayjs(carouselItems[0].published_at).locale('th').format('DD MMM BBBB HH:mm')}</span>
              <br />
              {carouselItems[0].title}
            </p>
          ) : (
            <>
              <span>{dayjs(carouselItems[0].published_at).locale('th').format('DD MMM BBBB HH:mm')}</span>
              <br />
              {carouselItems[0].title}
            </>
          )}
      </a>
    );
  };

  return (
    <section id='section-1' className='carousel-full-wrapper'>
      <ul className='slider'>
        <Carousel
          aria-hidden='true'
          additionalTransfrom={0}
          arrows={true}
          autoPlay={true}
          autoPlaySpeed={4000}
          centerMode={!isSmallScreen}
          className=''
          containerClass=''
          dotListClass=''
          draggable
          focusOnSelect={false}
          infinite
          itemClass=''
          keyBoardControl
          minimumTouchDrag={80}
          renderButtonGroupOutside={false}
          renderDotsOutside={true}
          showDots={true}
          customDot={<CustomDot />}
          sliderClass=''
          slidesToSlide={1}
          swipeable
          responsive={{
            desktop: {
              breakpoint: {
                max: 3000,
                min: 1024
              },
              items: 1,
              partialVisibilityGutter: 40,
              autoPlaySpeed: 3000
            },
            mobile: {
              breakpoint: {
                max: 640,
                min: 0
              },
              items: 1,
              partialVisibilityGutter: 10,
              autoPlaySpeed: 3000
            },
            tablet: {
              breakpoint: {
                max: 1024,
                min: 640
              },
              items: 1,
              partialVisibilityGutter: 30,
              autoPlaySpeed: 3000
            }
          }}
        >
          {!_.isEmpty(data) &&
            data.map((item, index) => (
              <li key={index} className='slide' style={{ width: '100%', maxWidth: '1250px', paddingRight: '20px' }}>
                {item && (
                  <a
                    //! DATALAYER
                    href={item.link}
                    onClick={() =>
                      sendDataLayerGlobal({
                        ...eventDataLayer,
                        data: {
                          ...eventDataLayer.data,
                          title: item?.title,
                          carousel: index + 1
                        }
                      })
                    }
                  >
                    <div className='card-item'>
                      <Image image={item.image} title={item.title} type='FEATURE_IMAGE' />
                    </div>
                    <div className='slider-content-box'>
                      <ul className='post-details'>
                        <li className='category'>{item.section.th}</li>
                      </ul>
                      {/* <h2 className='text-excerpt-2'>{item.title}</h2>
                      <p className='text-excerpt-2'>{item.blurb}</p> */}
                    </div>
                  </a>
                )}
              </li>
            ))}
        </Carousel>
      </ul>
    </section>
  );
};

export default CarouselFull;
